const Home = () => import(/* webpackChunkName: "sf-home" */ "../core/views/Home.vue");
const PageNotFound = () =>
  import(
    /* webpackChunkName: "sf-page-not-found" */ "../core/views/PageNotFound.vue"
  );
const Category = () =>
  import(
    /* webpackChunkName: "sf-category-page" */ "../core/views/Category.vue"
  );

// const Login = () =>
//   import(/* webpackChunkName: "sf-login" */ "../core/views/Login.vue");

// const ForgotPassword = () =>
//   import(
//     /* webpackChunkName: "sf-forgot-password" */ "../core/views/ForgotPassword.vue"
//   );
// const ResetPassword = () =>
//   import(
//     /* webpackChunkName: "sf-forgot-password" */ "../core/views/ResetPassword.vue"
//   );
// const CreateAccount = () =>
//   import(
//     /* webpackChunkName: "sf-create-account" */ "../core/views/authentication/sign-up/CreateAccount.vue"
//   );
// const Account = () =>
//   import(
//     /* webpackChunkName: "sf-create-account" */ "../core/views/account/Account.vue"
//   );
// const CmsPage = () =>
//   import(/* webpackChunkName: "sf-cms-page" */ "../core/views/StaticPage.vue");

const Product = () =>
  import(/* webpackChunkName: "sf-product" */ "../core/views/Product.vue");
const Cart = () =>
  import(/* webpackChunkName: "sf-cart" */ "../core/views/Cart.vue");
const Checkout = () => import(/* webpackChunkName: "sf-checkout" */ "../core/views/Checkout.vue");
// const PaymentSuccess = () =>
//   import(
//     /* webpackChunkName: "sf-payment-success" */ "../core/views/PaymentSuccess.vue"
//   );
// const PaymentCancel = () =>
//   import(
//     /* webpackChunkName: "sf-payment-cancel" */ "../core/views/PaymentCancel.vue"
//   );
// const OurShop = () =>
//   import(/* webpackChunkName: "sf-our-shop" */ "../core/views/OurShop.vue");
// const Brands = () =>
//   import(/* webpackChunkName: "sf-our-shop" */ "../core/views/Brands.vue");
// //const Brand = () =>
// //  import(/* webpackChunkName: "sf-our-shop" */ "../core/views/Brand.vue");

// const Search = () =>
//   import(/* webpackChunkName: "sf-our-shop" */ "../core/views/Search.vue");
// // const Sale = () =>
// //   import(/* webpackChunkName: "sf-our-shop" */ "../core/views/Sale.vue");
// const Faq = () =>
//   import(/* webpackChunkName: "sf-our-shop" */ "../core/views/Faq.vue");
// const Contact = () =>
//   import(/* webpackChunkName: "sf-our-shop" */ "../core/views/Contact.vue");
// const Blogs = () =>
//   import(/* webpackChunkName: "sf-our-shop" */ "../core/views/blog/Blogs.vue");
// const Blog = () =>
//   import(/* webpackChunkName: "sf-our-shop" */ "../core/views/blog/Blog.vue");

const routes = [
  { name: "home", path: "/", component: Home },
    { name: "error", path: "/error", component: PageNotFound },
  //   { name: "login", path: "/login", component: Login },
  //   {
  //     name: "forgot-password",
  //     path: "/forgot-password",
  //     component: ForgotPassword,
  //   },
  //   {
  //     name: "reset-password",
  //     path: "/customer/account/createPassword",
  //     component: ResetPassword,
  //   },
  //   { name: "create-account", path: "/create-account", component: CreateAccount },
  //   { name: "account", path: "/account", component: Account },
  //   { name: "product-SimpleProduct", path: "/product", component: Product },
  //   { name: "cms-page", path: "/cms-page", component: CmsPage },
    { name: "cart", path: "/checkout/cart", component: Cart },
    { name: "checkout", path: "/checkout", component: Checkout },
  //   { name: "ourshop", path: "/winkel/:code", component: OurShop },
  //   { name: "search", path: "/search", component: Search },
  //   // { name: "sale", path: "/sale", component: Sale },
  //   { name: "brands", path: "/merken", component: Brands },
  //   { name: "blogs", path: "/blog", component: Blogs },
  //   { name: "blog", path: "/blog/:code", component: Blog },
  //   //{ name: "brand", path: "/merken/:code", component: Brand },
  //   {
  //     name: "onpagesuccess",
  //     path: "/checkout/onepage/success",
  //     component: PaymentSuccess,
  //   },
  //   {
  //     name: "onpagecancel",
  //     path: "/checkout/onepage/cancel",
  //     component: PaymentCancel,
  //   },
  //   {
  //     name: "multisafepaysuccess",
  //     path: "/multisafepay/connect/success",
  //     component: PaymentSuccess,
  //   },
  //   {
  //     name: "multisafepaycancel",
  //     path: "/multisafepay/connect/cancel",
  //     component: PaymentCancel,
  //   },

  { name: "category-page", path: "/category-page", component: Category },
  //   {
  //     name: "product-ConfigurableProduct",
  //     path: "/productconfig",
  //     component: Product,
  //   },
  //   {
  //     name: "product-BundleProduct",
  //     path: "/productbundle",
  //     component: Product,
  //   },
    {
      name: "product-page",
      path: "/product",
      component: Product,
    },

  //   {
  //     name: "product-LookProduct",
  //     path: "/productlook",
  //     component: Product,
  //   },
  //   {
  //     name: "faq",
  //     path: "/faq",
  //     component: Faq,
  //   },
    { name: "page-not-found", path: "/page-not-found", component: PageNotFound },
  //   {
  //     name: "contact",
  //     path: "/contact",
  //     component: Contact,
  //   },
];

export default routes;
